"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
  methodType: null,
  btnClicked: null,
  addCreditBtnClicked: null,
  backArrowClicked: null,
  missingFieldsError: [],
  page: 1,
  residence: [],
  countiresList: [],
  withdrawals: [],
  errorText: [],
  residentCountryId: null,
  resultSet: [],
  profile:{},
};

export const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setProfileDetails(state,action){
      state.profile = action.payload;
    },
    setCurrentPage(state, action) {
      state.page = action.payload;
    },
    setResultSet(state, action) {
      state.resultSet = action.payload;
    },
    setBalance(state, action) {
      state.value = action.payload;
    },
    setTransferMethodSelection(state, action) {
      state.methodType = action.payload;
    },
    setTransferButtonClicked(state, action) {
      state.btnClicked = action.payload;
    },
    setAddCreditBtnClicked(state, action) {
      state.addCreditBtnClicked = action.payload;
    },
    setBackBtnClicked(state, action) {
      state.backArrowClicked = action.payload;
    },
    setMissingErrorFields(state, action) {
      state.missingFieldsError = action.payload;
    },
    setWrongFormatErrorText(state, action) {
      state.errorText = action.payload;
    },

    setResidence(state, action) {
      state.residence = action.payload;
    },
    setResidentCountryId(state, action) {
      state.residentCountryId = action.payload;
    },
    setAllCountries(state, action) {
      state.countiresList = action.payload;
    },
    setWithDrawals(state, action) {
      state.data = action.payload;
    },
    clearWithdrawals(state) {
      state.data = null;
    },
  },
});

export default balanceSlice.reducer;

export const {
  setProfileDetails,
  setCurrentPage,
  setBalance,
  setWrongFormatErrorText,
  setTransferMethodSelection,
  setTransferButtonClicked,
  setBackBtnClicked,
  setMissingErrorFields,
  setResultSet,
  setResidence,
  setAllCountries,
  setWithDrawals,
  clearWithdrawals,
  setResidentCountryId,
  setAddCreditBtnClicked,
} = balanceSlice.actions;

export const selectBalance = (state) => state.balance.value;
export const selectProfileDetails = (state) => state.balance.profile;
export const getResultSet = (state) => state.balance.resultSet;
export const getPage = (state) => state.balance.page;
export const selectTransferMethod = (state) => state.balance.methodType;
export const getTransferBtnClicked = (state) => state.balance.btnClicked;
export const getBackArrowBtnClicked = (state) => state.balance.backArrowClicked;
export const getAddCeditBtnClicked = (state) =>
  state.balance.addCreditBtnClicked;
export const getMissingErrorFields = (state) =>
  state.balance.missingFieldsError;

export const getResidence = (state) => state.balance.residence;
export const getAllCountries = (state) => state.balance.countiresList;
export const getAllWithdrawls = (state) => state.balance.withdrawals;
export const getErrorForTypes = (state) => state.balance.errorText;
export const getResidentCountryID = (state) => state.balance.residentCountryId;
