"use client";
export const GetCurrentDraws = "api/Draw/Draws/Current";
export const latestNewsPostEndpoint = "/api/latest-news-post";
export const latestGrandDrawEndpoint = "/api/latest-grand-draw";
export const authUserEndpoint = "/api/auth-user";
export const authUserVerificationEndpoint = "/api/auth-user-verified";
export const checkMobileEndpoint = "/api/players/CheckMobileNumberExists";
export const checkUsernameEndpoint = "/api/players/CheckUsernameExists";
export const contactFormProcess = "/api/contactus-process";
export const loginPath = "/api/login";
export const balancePath = "/api/balance";
export const cartAdd = "/api/cart/add";
export const cartPath = "/api/cart";
export const getBalanceTransactions = "/api/getBalanceTransactions";
export const getBalanceFreeTickets = "/api/freeTickets";
export const checkEmailExists = "/api/email/check";
export const profileEndpoint = "/api/profile";
export const changePasswordEndpoint = "/api/change-password";
export const restrictionsEndpoint = "/api/restrictions";
export const restrictionTypeEndpoint = "/api/restrictions/types";
export const passwordCodeEndpoint = "/api/password-code";
export const accountClosureReasonsEndpoint = "/api/account-closure/reasons";
export const rechargeBalanceEndpoint = "/api/recharge-balance";
export const ResetPasswordEndpoint = "/api/password-reset/change-password";
export const ReCaptchaVerifyEndpoint = "/api/recaptcha-v3/verify";
export const ContriesEndpoint = "/api/countries";
export const PasswordResetOtpCodeEndpoint = "/api/password-reset/otp-code";
export const OtpCodeVerificationEndpoint =
    "/api/password-reset/otp-code/verify";
export const Withdraw = "/api/withdraw";
export const verification = "/my-account/verification";
export const Login = "/login";
export const checkPhoneNumberEndpoint = "/api/check-phone-number";
export const checkEmailEndpoint = "/api/email/check";
export const gendersEndpoint = "/api/genders";
export const countriesEndpoint = "/api/countries";
export const nationalitiesEndpoint = "/api/nationalities";
export const sendRegisterOtpEndpoint = "/api/register/otp/send";
export const verifyRegisterOtpEndpoint = "/api/register/otp/verify";
export const verifyRecaptchaEndpoint = "/api/recaptcha-v3/verify";
export const registerEndpoint = "/api/register";
export const kycStatusEndpoint = "/api/kyc/status";
export const kycUploadEndpoint = "/api/kyc/upload";
export const gamesGroupsEndpoint = "/api/games/groups";
export const gamesGroupInfoEndpoint = "/api/games/group/information";
export const drawsEndpoint = "/api/draws";
export const registerOtpStatusEndpoint = "/api/register/otp/status";
