"use client";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideToaster } from "@/redux/slices/showToastSlice";
import { DIGITS } from "@/utils/constants";

export default function Toaster() {
  const dispatch = useDispatch();
  const { show, message, type } = useSelector((state) => state.toaster);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        dispatch(hideToaster());
      }, DIGITS.DIGIT_5000);
    }
  }, [show, dispatch]);
  if (!show) {
    return null;
  } else {
    return (
      <div className="fixed top-[85px] sm:top-[180px] right-0 flex items-center justify-center z-50 w-full sm:w-[75%] md:w-[50%] lg:w-[25%] px-4 sm:px-6 md:px-0">
        <div className="w-full flex h-[55px] sm:h-auto items-center shadow rounded-lg p-4 bg-white">
          <div
            className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${
              type == "error" ? "text-red-400" : "text-green-500"
            }`}
          >
            {type == "error" && (
              <i className="fa fa-times-circle !text-3xl font-extrabold"></i>
            )}
            {type == "success" && (
              <i className="fa fa-check-circle !text-3xl font-extrabold"></i>
            )}
          </div>
          <div className="ml-3 text-lg font-normal flex-grow reqular-iq">
            {message}
          </div>
          <button
            className="ml-auto border-0 bg-white"
            onClick={() => dispatch(hideToaster())}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    );
  }
}
