import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ar";
import { DIGITS } from "./constants";

export function validPhoneNumber(prefix = "", phoneNumber = "") {
    let valid = false;
    if (
        prefix &&
        phoneNumber &&
        typeof prefix === "string" &&
        typeof phoneNumber === "string" &&
        prefix?.length > DIGITS?.ZERO &&
        phoneNumber?.length > DIGITS?.ZERO
    ) {
        valid = true;
        const validUAEPrefixes = ["50", "52", "54", "55", "56", "58"];
        const validIRAQPrefixes = ["70", "77", "78", "79", "75", "76"];
        if (prefix === DIGITS?.DIGIT_971?.toString()) {
            const firstTwoDigits = phoneNumber.slice(DIGITS?.ZERO, DIGITS?.TWO);
            if (
                phoneNumber?.length !== DIGITS?.NINE ||
                !validUAEPrefixes.includes(firstTwoDigits)
            ) {
                valid = false;
            }
        } else if (prefix === DIGITS?.DIGIT_964?.toString()) {
            const firstTwoDigits = phoneNumber.slice(DIGITS?.ZERO, DIGITS?.TWO);
            if (
                phoneNumber?.length !== DIGITS?.TEN ||
                !validIRAQPrefixes.includes(firstTwoDigits)
            ) {
                valid = false;
            }
        } else if (prefix === "91" && phoneNumber?.length !== DIGITS?.TEN) {
            valid = false;
        }

        return valid;
    }
}

export function sortLineCardValues(values = [], sortType = "ascending") {
    if (
        Array.isArray(values) &&
        values?.length > 0 &&
        ["ascending", "descending"].includes(sortType)
    ) {
        // Create a shallow copy of the array
        const valuesCopy = values.slice();

        // Sort the copied array
        return valuesCopy.sort((a, b) => {
            if (sortType === "ascending") {
                return a - b;
            } else {
                return b - a;
            }
        });
    }

    return [];
}
export function splitWithComas(formatedResult) {
    const localeStringConversion = Number(formatedResult).toLocaleString(
        undefined,
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );
    return localeStringConversion;
}
export function splitWithCommasForInteger(formatedResult) {
    // Convert the input to a number (if it's not already)
    const number = Number(formatedResult);

    // Check if the number is an integer (no decimal part)
    const isInteger = Number.isInteger(number);

    // Format the number with commas
    const localeStringConversion = number.toLocaleString(undefined, {
        minimumFractionDigits: isInteger ? 0 : 2, // 0 decimals for integers
        maximumFractionDigits: isInteger ? 0 : 2, // 0 decimals for integers
    });

    return localeStringConversion;
}
export function newFavouriteNumberNotExists({
    name = "",
    newNumbers = [],
    lists = [],
}) {
    // Convert newNumbers array to a sorted, comma-separated string for comparison
    const newNumbersString = newNumbers
        .slice()
        .sort((a, b) => a - b)
        .join(",");

    // Check if the new name or numbers already exist in the lists
    const exists = lists.some(({ displayName, numbers }) => {
        // Convert existing numbers string to a sorted array
        const existingNumbersArray = numbers
            .split(",")
            .map(Number)
            .sort((a, b) => a - b);
        const existingNumbersString = existingNumbersArray.join(",");

        // Check if the display name matches
        if (displayName === name) {
            return true;
        }

        // Check if the numbers match
        if (
            newNumbers.length === existingNumbersArray.length &&
            newNumbersString === existingNumbersString
        ) {
            return true;
        }

        return false;
    });

    return !exists;
}

export function formatNumberWithComma(value = 0) {
    value = parseInt(value) || 0;

    if (!isNaN(value)) {
        return new Intl.NumberFormat("en-US").format(value);
    }
    return "";
}

export function convertCurrency(fromAmount, fromCurrencyER, toCurrencyER) {
    let finalRate = (fromCurrencyER / toCurrencyER) * fromAmount;

    if (isNaN(finalRate) || !isFinite(finalRate)) {
        return ""; // Return empty string if finalRate is not a valid number
    } else {
        return finalRate.toFixed(2);
    }
}

export function getTotalCartAmount(cartItems = [], price = 35) {
    let total = 0;
    price = parseInt(price);
    if (isNaN(price)) price = 0;

    if (Array.isArray(cartItems) && cartItems?.length > 0) {
        cartItems?.map((items) => {
            if (Array.isArray(items) && items?.length > 0) {
                total = total + items.length * price;
            }
        });
    }

    return total.toFixed(2);
}
export function formatDateInCartPage(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}
export function formatDDMMYYY(dateString = "", separator = ".") {
    if (!!dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const monthNumber = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}${separator}${monthNumber}${separator}${year}`;
    }
    return dateString;
}

export function convertStringToJSON(value = "") {
    if (!!value) {
        value = value.replace(/(\w+):/g, '"$1":');

        return JSON.parse(value);
    }

    return {};
}

export function formatDateString(dateString = "", locale = "en") {
    if (dateString) {
        return (
            formatDay(dateString, "en") +
            " " +
            formatMonth(dateString, locale) +
            " " +
            formatYear(dateString, "en")
        );
    }

    return "";
}

export function formatDay(dateString = "", locale = "en") {
    if (!!dateString) {
        return moment(dateString).locale(locale).format("DD");
    }

    return "";
}

export function formatYear(dateString = "", locale = "en") {
    if (!!dateString) {
        return moment(dateString).locale(locale).format("YYYY");
    }

    return "";
}

export function formatMonth(dateString = "", locale = "en") {
    if (!!dateString) {
        return moment(dateString).locale(locale).format("MMMM");
    }

    return "";
}

export function formatWeekDay(dateString = "", locale = "en") {
    const addColon = locale === "en" ? "," : "،";
    if (dateString) {
        return moment(dateString).locale(locale).format("dddd") + addColon;
    }

    return "";
}

export function convertToArabicMonth(month = "") {
    if (!!month) {
        const arMonths = [];
        arMonths["01"] = "يناير";
        arMonths["02"] = "شهر فبراير";
        arMonths["03"] = "يمشي";
        arMonths["04"] = "أبريل";
        arMonths["05"] = "يمكن";
        arMonths["06"] = "يونيو";
        arMonths["07"] = "يوليو";
        arMonths["08"] = "أغسطس";
        arMonths["09"] = "سبتمبر";
        arMonths["10"] = "اكتوبر";
        arMonths["11"] = "شهر نوفمبر";
        arMonths["12"] = "ديسمبر";
    }

    return month;
}

export function chunkArray(items = [], size = 1) {
    const chunkedArr = [];
    size = parseInt(size);
    if (Array.isArray(items) && size > 0) {
        for (let i = 0; i < items.length; i += size) {
            chunkedArr.push(items.slice(i, i + size));
        }
    }

    return chunkedArr;
}

export function validateRaffleDigit(value = "") {
    return value.replace(/[^0-9]/g, "");
}

export function validStringLength(value = "", min = 8, max = 30) {
    if (
        Number.isInteger(min) &&
        Number.isInteger(max) &&
        typeof value === "string"
    ) {
        const length = value.length;
        if (length >= min && length <= max) return true;
    }
    return false;
}

export function hasUpperCaseLetter(value = "") {
    if (value.match(/[A-Z]/)) return true;
    return false;
}

export function hasLowerCaseLetter(value = "") {
    if (value.match(/[a-z]/)) return true;
    return false;
}

export function hasLetter(value = "") {
    if (value.match(/[A-z]/)) return true;
    return false;
}

export function hasArabicLetter(value = "") {
    if (value.match(/^[\u0600-\u06FF\s]+$/)) return true;
    return false;
}

export function hasOnlyLetters(value = "") {
    // Check if the input contains only letters
    const letterAndSpacePattern = /^[a-zA-Z\u0600-\u06FF\s]+$/;
    return letterAndSpacePattern.test(value);
}

export function hasNumber(value = "") {
    if (value.match(/\d/)) return true;
    return false;
}
export function hasNonNumeric(value = "") {
    if (value.match(/\D/)) return true;
    return false;
}

export function hasSpecialCharacter(value = "") {
    const allowedCharactersRegex = /^[A-Za-z0-9@$!%*#?&_]*$/;
    const hasAllowedCharacterRegex = /[ @$!%*#?&_]/;

    const onlyAllowedCharacters = allowedCharactersRegex.test(value);
    const hasAllowedCharacter = hasAllowedCharacterRegex.test(value);

    return onlyAllowedCharacters && hasAllowedCharacter;
}

export function isValidNumber(value) {
    if (
        !!value &&
        !hasLetter(value) &&
        !hasSpecialCharacter(value) &&
        !hasArabicLetter(value) &&
        !value.includes("-") &&
        !value.includes("=") &&
        !value.includes("(") &&
        !value.includes(")")
    ) {
        return true;
    }
    return false;
}

export function calculateProgressBar(
    num_done = 1,
    total_done = 1,
    total_percentage = 100
) {
    const done_perc = Math.round((num_done / total_done) * total_percentage);
    return done_perc;
}

export function evaluateProgressBar(
    inputs = [],
    current_step = 1,
    total_steps = 3
) {
    var count = inputs?.length || 0;
    var length = inputs.filter(
        (input) => !!input && input !== 0 && input !== ""
    ).length;

    var done_perc =
        Math.round(
            (((current_step - 1) * 100) / total_steps +
                Math.floor((length * (100 / total_steps)) / count)) *
                100,
            2
        ) / 100;

    return done_perc;
}

export function convertToDDMMYYYY(isoDateString = "") {
    if (!!isoDateString) {
        const date = new Date(isoDateString);

        const day = date.getDate().toString().padStart(2, "0"); // zero-based
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // zero-based
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    return "DD.MM.YYYY";
}

export function formatDateToDDMMYYY(
    dateString = "",
    separator = " ",
    lang = "en-US"
) {
    if (!!dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const monthName = date.toLocaleDateString(lang, { month: "long" });
        const year = date.getFullYear();
        return `${day}${separator}${monthName}${separator}${year}`;
    }
    return dateString;
}

export function generateUID() {
    return uuidv4();
}

export function isValidRaffleDigit(value = 0, min = 1, max = 49) {
    value = parseInt(value);
    if (value >= min && value <= max) return true;
    return false;
}

export function validEmailFormat(email = "") {
    if (!!email) {
        const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (emailRegex.test(email)) return true;
    }

    return false;
}

export function isValidPassword(password = "") {
    if (
        !validStringLength(password) ||
        !hasNonNumeric(password) ||
        !hasNumber(password)
    )
        return false;
    return true;
}

export function isValidDate(dateString = "") {
    if (!!dateString) {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    }

    return false;
}

export function validDateFormat(date = "", format = "DD.MM.YYYY") {
    if (!!date) {
        let separator = "";
        format = format.toUpperCase();
        if (format.includes(".")) separator = ".";
        else if (format.includes("-")) separator = "-";
        else if (format.includes("/")) separator = "/";

        if (!!separator) {
            const formatParts = format.split(separator);
            const dateParts = date.split(separator);

            if (
                Array.isArray(formatParts) &&
                formatParts.length === 3 &&
                Array.isArray(dateParts) &&
                dateParts.length === formatParts.length
            ) {
                const monthIndex = formatParts.indexOf("MM");
                const dayIndex = formatParts.indexOf("DD");
                const month = parseInt(dateParts[monthIndex]) || 0;
                const day = parseInt(dateParts[dayIndex]) || 0;

                if (
                    typeof month === "number" &&
                    month >= 1 &&
                    month <= 12 &&
                    typeof day === "number" &&
                    day >= 1 &&
                    day <= 31
                ) {
                    const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;

                    if (dateRegex.test(date)) return true;
                }
            }
        }
    }
    return false;
}

export function addDaysFromToday(numberOfDays = 0) {
    if (!isNaN(numberOfDays) && numberOfDays >= 0) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + parseInt(numberOfDays));

        // Format the date as DD-MM-YYYY
        const day = String(futureDate.getDate()).padStart(2, "0");
        const month = String(futureDate.getMonth() + 1).padStart(2, "0");
        const year = futureDate.getFullYear();

        return day + "-" + month + "-" + year;
    }
    return "";
}

export function addMonthsFromToday(numberOfMonths = 0) {
    if (!isNaN(numberOfMonths) && numberOfMonths >= 0) {
        const today = new Date();
        const futureDate = new Date(
            today.setMonth(today.getMonth() + parseInt(numberOfMonths))
        );

        // Format the date as DD-MM-YYYY
        const day = String(futureDate.getDate()).padStart(2, "0");
        const month = String(futureDate.getMonth() + 1).padStart(2, "0");
        const year = futureDate.getFullYear();

        return day + "-" + month + "-" + year;
    }
    return "";
}

export function randomSelectNumbers({
    totalCombination = 5,
    totalLineDigits = 49,
}) {
    const newSelectedNumbers = new Set();

    while (newSelectedNumbers.size < totalCombination) {
        const randomNumber = Math.floor(Math.random() * totalLineDigits) + 1;
        newSelectedNumbers.add(randomNumber);
    }

    return newSelectedNumbers;
}

export function getNationalityCountryName(code = "") {
    if (!!code) {
        const country = nationalityCountriesISO().find(
            ({ value }) => value.toUpperCase() === code.toUpperCase()
        );
        return country?.text || "";
    }
    return "";
}
getNationalityCountryName.propTypes = {
    /**
     * Country ISO 2-letter Code
     */
    code: PropTypes.string,
};

/**
 * List of country nationalities and 2-ISO codes.
 */
export function nationalityCountriesISO() {
    return [
        { value: "ae", text: "United Arab Emirates" },
        { value: "af", text: "Afganistan" },
        { value: "al", text: "Albania" },
        { value: "dz", text: "Algeria" },
        { value: "as", text: "American Samoa" },
        { value: "ad", text: "Andorra" },
        { value: "ao", text: "Angola" },
        { value: "ai", text: "Anguilla" },
        { value: "aq", text: "Antarctica" },
        { value: "ag", text: "Antigua and Barbuda" },
        { value: "ar", text: "Argentina" },
        { value: "am", text: "Armenia" },
        { value: "aw", text: "Aruba" },
        { value: "au", text: "Australia" },
        { value: "at", text: "Austria" },
        { value: "az", text: "Azerbaijan" },
        { value: "bs", text: "Bahamas" },
        { value: "bh", text: "Bahrain" },
        { value: "bd", text: "Bangladesh" },
        { value: "bb", text: "Barbados" },
        { value: "by", text: "Belarus" },
        { value: "be", text: "Belgium" },
        { value: "bz", text: "Belize" },
        { value: "bj", text: "Benin" },
        { value: "bm", text: "Bermuda" },
        { value: "bt", text: "Bhutan" },
        { value: "bo", text: "Bolivia" },
        { value: "ba", text: "Bosnia-Herzegovina" },
        { value: "bw", text: "Botswana" },
        { value: "bv", text: "Bouvet Island" },
        { value: "br", text: "Brazil" },
        { value: "io", text: "British Indian Ocean Territory" },
        { value: "vg", text: "British Virgin Islands" },
        { value: "bn", text: "Brunei Darussalam" },
        { value: "bg", text: "Bulgaria" },
        { value: "bf", text: "Burkina Faso" },
        { value: "bi", text: "Burundi" },
        { value: "kh", text: "Cambodia" },
        { value: "cm", text: "Cameroon" },
        { value: "ca", text: "Canada" },
        { value: "cv", text: "Cape Verde Islands" },
        { value: "ky", text: "Cayman Islands" },
        { value: "cf", text: "Central African Republic" },
        { value: "td", text: "Chad" },
        { value: "cl", text: "Chile" },
        { value: "cn", text: "China" },
        { value: "cx", text: "Christmas Island" },
        { value: "cc", text: "Cocos (Keeling) Islands" },
        { value: "co", text: "Colombia" },
        { value: "km", text: "Comoros" },
        { value: "cg", text: "Congo (Brazzaville)" },
        { value: "cd", text: "Congo (Kinshasa)" },
        { value: "ck", text: "Cook Islands" },
        { value: "cr", text: "Costa Rica" },
        { value: "hr", text: "Croatia" },
        { value: "cu", text: "Cuba" },
        { value: "cy", text: "Cyprus" },
        { value: "cz", text: "Czech Republic" },
        { value: "dk", text: "Denmark" },
        { value: "dj", text: "Djibouti" },
        { value: "dm", text: "Dominica" },
        { value: "do", text: "Dominican Republic" },
        { value: "tl", text: "East Timor" },
        { value: "ec", text: "Ecuador" },
        { value: "eg", text: "Egypt" },
        { value: "sv", text: "El Salvador" },
        { value: "gq", text: "Equatorial Guinea" },
        { value: "er", text: "Eritrea" },
        { value: "ee", text: "Estonia" },
        { value: "et", text: "Ethiopia" },
        { value: "fo", text: "Faeroe Islands" },
        { value: "fk", text: "Falkland Islands" },
        { value: "fj", text: "Fiji" },
        { value: "fi", text: "Finland" },
        { value: "fr", text: "France" },
        { value: "gf", text: "French Guiana" },
        { value: "pf", text: "French Polynesia" },
        { value: "tf", text: "French Southern Territories" },
        { value: "ga", text: "Gabon" },
        { value: "gm", text: "Gambia" },
        { value: "ge", text: "Georgia" },
        { value: "de", text: "Germany" },
        { value: "gh", text: "Ghana" },
        { value: "gi", text: "Gibralter" },
        { value: "gr", text: "Greece" },
        { value: "gl", text: "Greenland" },
        { value: "gd", text: "Grenada" },
        { value: "gp", text: "Guadeloupe" },
        { value: "gu", text: "Guam" },
        { value: "gt", text: "Guatemala" },
        { value: "gg", text: "Guernsey" },
        { value: "gn", text: "Guinea" },
        { value: "gw", text: "Guinea-Bissau" },
        { value: "gy", text: "Guyana" },
        { value: "ht", text: "Haiti" },
        { value: "hm", text: "Heard Island and McDonald Islands" },
        { value: "va", text: "Holy See" },
        { value: "hn", text: "Honduras" },
        { value: "hk", text: "Hong Kong" },
        { value: "hu", text: "Hungary" },
        { value: "is", text: "Iceland" },
        { value: "in", text: "India" },
        { value: "id", text: "Indonesia" },
        { value: "ir", text: "Iran" },
        { value: "iq", text: "Iraq" },
        { value: "ie", text: "Ireland" },
        { value: "im", text: "Isle of Man" },
        { value: "il", text: "Israel" },
        { value: "it", text: "Italy" },
        { value: "ci", text: "Ivory Coast" },
        { value: "jm", text: "Jamaica" },
        { value: "jp", text: "Japan" },
        { value: "je", text: "Jersey" },
        { value: "jo", text: "Jordan" },
        { value: "kz", text: "Kazakhstan" },
        { value: "ke", text: "Kenya" },
        { value: "ki", text: "Kiribati" },
        { value: "xk", text: "Kosovo" },
        { value: "kw", text: "Kuwait" },
        { value: "kg", text: "Kyrgyzstan" },
        { value: "la", text: "Laos" },
        { value: "lv", text: "Latvia" },
        { value: "lb", text: "Lebanon" },
        { value: "ls", text: "Lesotho" },
        { value: "lr", text: "Liberia" },
        { value: "ly", text: "Libya" },
        { value: "li", text: "Liechtenstein" },
        { value: "lt", text: "Lithuania" },
        { value: "lu", text: "Luxembourg" },
        { value: "mo", text: "Macau" },
        { value: "mk", text: "Macedonia" },
        { value: "mg", text: "Madagascar" },
        { value: "mw", text: "Malawi" },
        { value: "my", text: "Malaysia" },
        { value: "mv", text: "Maldives" },
        { value: "ml", text: "Mali" },
        { value: "mt", text: "Malta" },
        { value: "mh", text: "Marshall Islands" },
        { value: "mq", text: "Martinique" },
        { value: "mr", text: "Mauritania" },
        { value: "mu", text: "Mauritius" },
        { value: "yt", text: "Mayotte" },
        { value: "mx", text: "Mexico" },
        { value: "fm", text: "Micronesia" },
        { value: "md", text: "Moldova" },
        { value: "mc", text: "Monaco" },
        { value: "mn", text: "Mongolia" },
        { value: "me", text: "Montenegro" },
        { value: "ms", text: "Montserrat" },
        { value: "ma", text: "Morocco" },
        { value: "mz", text: "Mozambique" },
        { value: "mm", text: "Myanmar" },
        { value: "na", text: "Namibia" },
        { value: "nr", text: "Nauru" },
        { value: "np", text: "Nepal" },
        { value: "nl", text: "Netherlands" },
        { value: "an", text: "Netherlands Antilles" },
        { value: "nc", text: "New Caledonia" },
        { value: "nz", text: "New Zealand" },
        { value: "ni", text: "Nicaragua" },
        { value: "ne", text: "Niger" },
        { value: "ng", text: "Nigeria" },
        { value: "nu", text: "Niue" },
        { value: "nf", text: "Norfolk Island" },
        { value: "kp", text: "North Korea" },
        { value: "mp", text: "Northern Mariana Islands" },
        { value: "no", text: "Norway" },
        { value: "om", text: "Oman" },
        { value: "pk", text: "Pakistan" },
        { value: "pw", text: "Palau" },
        { value: "ps", text: "Palestinian Territories" },
        { value: "pa", text: "Panama" },
        { value: "pg", text: "Papua New Guinea" },
        { value: "py", text: "Paraguay" },
        { value: "pe", text: "Peru" },
        { value: "ph", text: "Philippines" },
        { value: "pn", text: "Pitcairn Islands" },
        { value: "pl", text: "Poland" },
        { value: "pt", text: "Portugal" },
        { value: "pr", text: "Puerto Rico" },
        { value: "qa", text: "Qatar" },
        { value: "ro", text: "Romania" },
        { value: "ru", text: "Russia" },
        { value: "rw", text: "Rwanda" },
        { value: "re", text: "Réunion" },
        { value: "kn", text: "Saint Christopher and Nevis" },
        { value: "sh", text: "Saint Helena" },
        { value: "lc", text: "Saint Lucia" },
        { value: "pm", text: "Saint Pierre and Miquelon" },
        { value: "vc", text: "Saint Vincent and The Grenadines" },
        { value: "ws", text: "Samoa" },
        { value: "sm", text: "San Marino" },
        { value: "st", text: "Sao Tome and Principe" },
        { value: "sa", text: "Saudi Arabia" },
        { value: "sn", text: "Senegal" },
        { value: "rs", text: "Serbia" },
        { value: "sc", text: "Seychelles" },
        { value: "sl", text: "Sierra Leone" },
        { value: "sg", text: "Singapore" },
        { value: "sk", text: "Slovakia" },
        { value: "si", text: "Slovenia" },
        { value: "sb", text: "Solomon Islands" },
        { value: "so", text: "Somalia" },
        { value: "za", text: "South Africa" },
        { value: "gs", text: "South Georgia and The South Sandwish Islands" },
        { value: "kr", text: "South Korea" },
        { value: "es", text: "Spain" },
        { value: "lk", text: "Sri Lanka" },
        { value: "sd", text: "Sudan" },
        { value: "sr", text: "Suriname" },
        { value: "sj", text: "Svalbard and Jan Mayen" },
        { value: "sz", text: "Swaziland" },
        { value: "se", text: "Sweden" },
        { value: "ch", text: "Switzerland" },
        { value: "sy", text: "Syria" },
        { value: "tw", text: "Taiwan" },
        { value: "tj", text: "Tajikistan" },
        { value: "tz", text: "Tanzania" },
        { value: "th", text: "Thailand" },
        { value: "tg", text: "Togo" },
        { value: "tk", text: "Tokelau" },
        { value: "to", text: "Tonga" },
        { value: "tt", text: "Trinidad and Tobago" },
        { value: "tn", text: "Tunisia" },
        { value: "tr", text: "Turkey" },
        { value: "tm", text: "Turkmenistan" },
        { value: "tc", text: "Turks and Caicos Islands" },
        { value: "tv", text: "Tuvalu" },
        { value: "ug", text: "Uganda" },
        { value: "ua", text: "Ukraine" },
        { value: "uk", text: "United Kingdom" },
        { value: "us", text: "United States of America" },
        { value: "uy", text: "Uruguay" },
        { value: "uz", text: "Uzbekistan" },
        { value: "vu", text: "Vanuatu" },
        { value: "ve", text: "Venezuela" },
        { value: "vn", text: "Vietnam" },
        { value: "wf", text: "Wallis and Futuna" },
        { value: "eh", text: "Western Sahara" },
        { value: "ye", text: "Yemen" },
        { value: "zm", text: "Zambia" },
        { value: "zw", text: "Zimbabwe" },
        { value: "ax", text: "Åland Islands" },
    ];
}

export function getResidenceCountryName(code = "") {
    if (!!code) {
        const country = residenceCountriesISO().find(
            ({ value }) => value.toUpperCase() === code.toUpperCase()
        );
        return country?.text || "";
    }
    return "";
}
getResidenceCountryName.propTypes = {
    /**
     * Country ISO 2-letter Code
     */
    code: PropTypes.string,
};

/**
 * List of country residence and 2-ISO codes.
 */
export function residenceCountriesISO() {
    return [
        { value: "ae", text: "United Arab Emirates" },
        { value: "al", text: "Albania" },
        { value: "dz", text: "Algeria" },
        { value: "as", text: "American Samoa" },
        { value: "ad", text: "Andorra" },
        { value: "ao", text: "Angola" },
        { value: "ai", text: "Anguilla" },
        { value: "aq", text: "Antarctica" },
        { value: "ag", text: "Antigua and Barbuda" },
        { value: "ar", text: "Argentina" },
        { value: "am", text: "Armenia" },
        { value: "aw", text: "Aruba" },
        { value: "au", text: "Australia" },
        { value: "at", text: "Austria" },
        { value: "az", text: "Azerbaijan" },
        { value: "bs", text: "Bahamas" },
        { value: "bh", text: "Bahrain" },
        { value: "bd", text: "Bangladesh" },
        { value: "bb", text: "Barbados" },
        { value: "by", text: "Belarus" },
        { value: "be", text: "Belgium" },
        { value: "bz", text: "Belize" },
        { value: "bj", text: "Benin" },
        { value: "bm", text: "Bermuda" },
        { value: "bt", text: "Bhutan" },
        { value: "bo", text: "Bolivia" },
        { value: "ba", text: "Bosnia-Herzegovina" },
        { value: "bw", text: "Botswana" },
        { value: "bv", text: "Bouvet Island" },
        { value: "br", text: "Brazil" },
        { value: "io", text: "British Indian Ocean Territory" },
        { value: "vg", text: "British Virgin Islands" },
        { value: "bn", text: "Brunei Darussalam" },
        { value: "bg", text: "Bulgaria" },
        { value: "bf", text: "Burkina Faso" },
        { value: "bi", text: "Burundi" },
        { value: "kh", text: "Cambodia" },
        { value: "cm", text: "Cameroon" },
        { value: "ca", text: "Canada" },
        { value: "cv", text: "Cape Verde Islands" },
        { value: "ky", text: "Cayman Islands" },
        { value: "td", text: "Chad" },
        { value: "cl", text: "Chile" },
        { value: "cn", text: "China" },
        { value: "cx", text: "Christmas Island" },
        { value: "cc", text: "Cocos (Keeling) Islands" },
        { value: "co", text: "Colombia" },
        { value: "km", text: "Comoros" },
        { value: "ck", text: "Cook Islands" },
        { value: "cr", text: "Costa Rica" },
        { value: "hr", text: "Croatia" },
        { value: "cy", text: "Cyprus" },
        { value: "cz", text: "Czech Republic" },
        { value: "dk", text: "Denmark" },
        { value: "dj", text: "Djibouti" },
        { value: "dm", text: "Dominica" },
        { value: "do", text: "Dominican Republic" },
        { value: "tl", text: "East Timor" },
        { value: "ec", text: "Ecuador" },
        { value: "eg", text: "Egypt" },
        { value: "sv", text: "El Salvador" },
        { value: "gq", text: "Equatorial Guinea" },
        { value: "er", text: "Eritrea" },
        { value: "ee", text: "Estonia" },
        { value: "et", text: "Ethiopia" },
        { value: "fo", text: "Faeroe Islands" },
        { value: "fk", text: "Falkland Islands" },
        { value: "fj", text: "Fiji" },
        { value: "fi", text: "Finland" },
        { value: "fr", text: "France" },
        { value: "gf", text: "French Guiana" },
        { value: "pf", text: "French Polynesia" },
        { value: "tf", text: "French Southern Territories" },
        { value: "ga", text: "Gabon" },
        { value: "gm", text: "Gambia" },
        { value: "ge", text: "Georgia" },
        { value: "de", text: "Germany" },
        { value: "gh", text: "Ghana" },
        { value: "gi", text: "Gibralter" },
        { value: "gr", text: "Greece" },
        { value: "gl", text: "Greenland" },
        { value: "gd", text: "Grenada" },
        { value: "gp", text: "Guadeloupe" },
        { value: "gu", text: "Guam" },
        { value: "gt", text: "Guatemala" },
        { value: "gg", text: "Guernsey" },
        { value: "gn", text: "Guinea" },
        { value: "gw", text: "Guinea-Bissau" },
        { value: "gy", text: "Guyana" },
        { value: "ht", text: "Haiti" },
        { value: "hm", text: "Heard Island and McDonald Islands" },
        { value: "va", text: "Holy See" },
        { value: "hn", text: "Honduras" },
        { value: "hk", text: "Hong Kong" },
        { value: "hu", text: "Hungary" },
        { value: "is", text: "Iceland" },
        { value: "in", text: "India" },
        { value: "id", text: "Indonesia" },
        { value: "ie", text: "Ireland" },
        { value: "im", text: "Isle of Man" },
        { value: "il", text: "Israel" },
        { value: "it", text: "Italy" },
        { value: "ci", text: "Ivory Coast" },
        { value: "jm", text: "Jamaica" },
        { value: "jp", text: "Japan" },
        { value: "je", text: "Jersey" },
        { value: "jo", text: "Jordan" },
        { value: "kz", text: "Kazakhstan" },
        { value: "ke", text: "Kenya" },
        { value: "ki", text: "Kiribati" },
        { value: "xk", text: "Kosovo" },
        { value: "kw", text: "Kuwait" },
        { value: "kg", text: "Kyrgyzstan" },
        { value: "la", text: "Laos" },
        { value: "lv", text: "Latvia" },
        { value: "lb", text: "Lebanon" },
        { value: "ls", text: "Lesotho" },
        { value: "lr", text: "Liberia" },
        { value: "li", text: "Liechtenstein" },
        { value: "lt", text: "Lithuania" },
        { value: "lu", text: "Luxembourg" },
        { value: "mo", text: "Macau" },
        { value: "mk", text: "Macedonia" },
        { value: "mg", text: "Madagascar" },
        { value: "mw", text: "Malawi" },
        { value: "my", text: "Malaysia" },
        { value: "mv", text: "Maldives" },
        { value: "mt", text: "Malta" },
        { value: "mh", text: "Marshall Islands" },
        { value: "mq", text: "Martinique" },
        { value: "mr", text: "Mauritania" },
        { value: "mu", text: "Mauritius" },
        { value: "yt", text: "Mayotte" },
        { value: "mx", text: "Mexico" },
        { value: "fm", text: "Micronesia" },
        { value: "md", text: "Moldova" },
        { value: "mc", text: "Monaco" },
        { value: "mn", text: "Mongolia" },
        { value: "me", text: "Montenegro" },
        { value: "ms", text: "Montserrat" },
        { value: "ma", text: "Morocco" },
        { value: "mz", text: "Mozambique" },
        { value: "mm", text: "Myanmar" },
        { value: "na", text: "Namibia" },
        { value: "nr", text: "Nauru" },
        { value: "np", text: "Nepal" },
        { value: "nl", text: "Netherlands" },
        { value: "an", text: "Netherlands Antilles" },
        { value: "nc", text: "New Caledonia" },
        { value: "nz", text: "New Zealand" },
        { value: "ni", text: "Nicaragua" },
        { value: "ne", text: "Niger" },
        { value: "ng", text: "Nigeria" },
        { value: "nu", text: "Niue" },
        { value: "nf", text: "Norfolk Island" },
        { value: "mp", text: "Northern Mariana Islands" },
        { value: "no", text: "Norway" },
        { value: "om", text: "Oman" },
        { value: "pk", text: "Pakistan" },
        { value: "pw", text: "Palau" },
        { value: "ps", text: "Palestinian Territories" },
        { value: "pa", text: "Panama" },
        { value: "pg", text: "Papua New Guinea" },
        { value: "py", text: "Paraguay" },
        { value: "pe", text: "Peru" },
        { value: "ph", text: "Philippines" },
        { value: "pn", text: "Pitcairn Islands" },
        { value: "pl", text: "Poland" },
        { value: "pt", text: "Portugal" },
        { value: "pr", text: "Puerto Rico" },
        { value: "qa", text: "Qatar" },
        { value: "ro", text: "Romania" },
        { value: "rw", text: "Rwanda" },
        { value: "re", text: "Réunion" },
        { value: "kn", text: "Saint Christopher and Nevis" },
        { value: "sh", text: "Saint Helena" },
        { value: "lc", text: "Saint Lucia" },
        { value: "pm", text: "Saint Pierre and Miquelon" },
        { value: "vc", text: "Saint Vincent and The Grenadines" },
        { value: "ws", text: "Samoa" },
        { value: "sm", text: "San Marino" },
        { value: "st", text: "Sao Tome and Principe" },
        { value: "sa", text: "Saudi Arabia" },
        { value: "sn", text: "Senegal" },
        { value: "rs", text: "Serbia" },
        { value: "sc", text: "Seychelles" },
        { value: "sl", text: "Sierra Leone" },
        { value: "sg", text: "Singapore" },
        { value: "sk", text: "Slovakia" },
        { value: "si", text: "Slovenia" },
        { value: "sb", text: "Solomon Islands" },
        { value: "za", text: "South Africa" },
        { value: "gs", text: "South Georgia and The South" },
        { value: "kr", text: "South Korea" },
        { value: "es", text: "Spain" },
        { value: "lk", text: "Sri Lanka" },
        { value: "sr", text: "Suriname" },
        { value: "sj", text: "Svalbard and Jan Mayen" },
        { value: "sz", text: "Swaziland" },
        { value: "se", text: "Sweden" },
        { value: "ch", text: "Switzerland" },
        { value: "tw", text: "Taiwan" },
        { value: "tj", text: "Tajikistan" },
        { value: "tz", text: "Tanzania" },
        { value: "th", text: "Thailand" },
        { value: "tg", text: "Togo" },
        { value: "tk", text: "Tokelau" },
        { value: "to", text: "Tonga" },
        { value: "tt", text: "Trinidad and Tobago" },
        { value: "tn", text: "Tunisia" },
        { value: "tr", text: "Turkey" },
        { value: "tm", text: "Turkmenistan" },
        { value: "tc", text: "Turks and Caicos Islands" },
        { value: "tv", text: "Tuvalu" },
        { value: "ug", text: "Uganda" },
        { value: "ua", text: "Ukraine" },
        { value: "uk", text: "United Kingdom" },
        { value: "us", text: "United States of America" },
        { value: "uy", text: "Uruguay" },
        { value: "uz", text: "Uzbekistan" },
        { value: "vu", text: "Vanuatu" },
        { value: "ve", text: "Venezuela" },
        { value: "vn", text: "Vietnam" },
        { value: "wf", text: "Wallis and Futuna" },
        { value: "eh", text: "Western Sahara" },
        { value: "zm", text: "Zambia" },
        { value: "zw", text: "Zimbabwe" },
        { value: "ax", text: "Åland Islands" },
    ];
}

function padNumber(number) {
    return number < 10 ? `0${number}` : `${number}`;
}
// Can be used for timer counts in homepage and live draw show page
// calculateTimeLeft calculate the remaining time between the current moment and a specified future time (showTime)
export function calculateTimeLeft(showTime = null) {
    // Get the current timestamp
    const now = new Date().getTime();

    // Calculate the difference between the target time and the current time
    const difference = new Date(showTime).getTime() - now;

    if (difference <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    // Calculate the days, hours, minutes, and seconds remaining
    const days = padNumber(Math.floor(difference / (1000 * 60 * 60 * 24)));
    const hours = padNumber(
        Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    const minutes = padNumber(
        Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    );
    const seconds = padNumber(Math.floor((difference % (1000 * 60)) / 1000));
    // Return an object with the calculated values
    return { days, hours, minutes, seconds };
}

export const formatNumberToFixed = (inputNumber) => {
    const formattedNumber = parseFloat(inputNumber).toFixed(2);
    return formattedNumber;
};

export const residence = (residences, countryCode) => {
    let option = {};

    if (Array.isArray(residences) && residences.length > 0) {
        option = residences.find(
            (res) => res.value.toLowerCase() === countryCode?.toLowerCase()
        );

        if (!option) return option;
    }

    return option;
};
// Check if the input contains only letters and spaces
export const isString = (input) => {
    return /^[a-zA-Z\s]+$/.test(input);
};
export const isAlphanumeric = (input, minLen, maxLen) => {
    const regex = new RegExp(`^[a-zA-Z0-9]{${minLen},${maxLen}}$`);
    return regex.test(input);
};
// Function to validate a string containing only digits with a given length range
export const isDigitStringWithLength = (input, minLen, maxLen) => {
    const regex = new RegExp(`^[0-9]{${minLen},${maxLen}}$`);
    return regex.test(input);
};
export const formatNumber = (number) => {
    let numberStr = number.toString(); // Convert number to string
    if (numberStr.length > 2) {
        return numberStr.slice(0, 2) + ".."; // Keep first 2 digits and append ".."
    }
    return numberStr; // Return the original number if length is 2 or less
};

export function wrapNumbers(htmlContent) {
    return htmlContent.replace(/(\d{8,})/g, (match) => {
        // This will wrap any sequence of 8 or more digits with a <span> within an <a> tag
        return `<span class="digits-iq">${match}</span>`;
    });
}

export function checkNumberAndWrap(
    data,
    type,
    wrapperElement = "span",
    className = "digits-iq"
) {
    if (type === "text" && data != "") {
        return data?.replace(/(\d+)/g, (match) => {
            return `<${wrapperElement} class="${className}">${match}</${wrapperElement}>`;
        });
    }
    return null;
}

// use this function any number to be wrap and it is in the anchor tag or link
export function handleMarkableOutput(
    data,
    type = "text",
    wrapperElement = "span",
    className = "digits-iq"
) {
    if (data) {
        const anchorTagPattern = /<a\s+[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gi;
        let anchorTags = [];
        let hashString = "#";
        let hashCount = 1;
        // step-1 filter anchor tag and store links into array
        let modifiedData = data.replace(
            anchorTagPattern,
            (match, href, content) => {
                anchorTags.push(href);
                const currentHash = hashString;
                hashString += "#";
                return match.replace(href, currentHash);
            }
        );
        // step-2 wrap the numbers with span tag
        modifiedData = modifiedData.replace(/(\d+)/g, (match) => {
            return `<${wrapperElement} class="${className}">${match}</${wrapperElement}>`;
        });
        // step-3 replace the hash with original link
        modifiedData = modifiedData.replace(
            /<a\s+[^>]*href="(#+)"[^>]*>/gi,
            (match, hash) => {
                return match.replace(hash, anchorTags[hashCount++ - 1]);
            }
        );

        return modifiedData;
    }
    return null;
}

export function wrapNumbersExceptInLinks(
    data,
    wrapperElement = "span",
    className = "digits-iq"
) {
    if (data) {
        const anchorTagPattern = /\((.*?)\)/g;
        let anchorTags = [];
        let hashString = "#";
        let hashCount = 1;

        // Step 1: Filter anchor tags and store links into an array
        let modifiedData = data.replace(anchorTagPattern, (match, href) => {
            anchorTags.push(href);
            const currentHash = hashString;
            hashString += "#";
            return match.replace(href, currentHash);
        });

        // Step 2: Wrap the numbers with the specified wrapper element
        modifiedData = modifiedData.replace(/(\d+)/g, (match) => {
            return `<${wrapperElement} class="${className}">${match}</${wrapperElement}>`;
        });

        // Step 3: Replace the hash with the original link
        modifiedData = modifiedData.replace(anchorTagPattern, (match, hash) => {
            return match.replace(hash, anchorTags[hashCount++ - 1]);
        });

        return modifiedData;
    }

    return null;
}

//for gtm data
export const generateCartItem = (cart, DrawDate, itemid) => {
    const items = cart?.map((subArray, index) => {
        return {
            item_id: itemid.toString() || 102,
            item_name: `TICKET_${index + 1}`,
            coupon: "",
            discount: 0,
            index: 0,
            item_category: "Raffle",
            item_variant: "Grand Draw",
            price: 3000,
            quantity: subArray.length,
            set_sequence: `${subArray.join(" | ")}`,
            draw_date: DrawDate,
        };
    });

    const totalValue = cart.flat().length;

    return { item: items, value: totalValue };
};

export const generateCartContent = (cart, DrawDate, itemid) => {
    const items = cart?.map((subArray, index) => {
        return {
            content_id: itemid.toString() || 102,
            item_name: `TICKET_${index + 1}`,
            item_category: "Raffle",
            item_variant: "Grand Draw",
            price: 3000,
            quantity: subArray.length,
            set_sequence: `${subArray.join(" | ")}`,
            draw_date: DrawDate,
        };
    });

    const totalValue = cart.flat().length;

    return { item: items, value: totalValue };
};

export const gtmDateFormat = (dateString) => {
    const date = new Date(dateString);

    // Get the day of the week
    const options = { weekday: "long" };
    const dayOfWeek = date.toLocaleDateString("en-US", options);

    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Format the result
    const formattedDate = `${dayOfWeek} ${day}-${month}-${year}`;
    return formattedDate;
};
export function wrapNumbersOnlyContent(
    content,
    wrapperElement = "span",
    className = "digits-iq"
) {
    // const regex = /(?<!<\/?[a-z][^>]*?>)(\d+)(?![^<>]*?>)/g; // this is used for space before number
    const regex = /(?<!<\/?[a-z]>)(\d+)(?![^<>]*?>)/g; // this is used for no space before number

    if (!content) return content;
    return content.replace(regex, (match) => {
        return `<${wrapperElement} class="${className}">${match}</${wrapperElement}>`;
    });
}

export const markerAnchorComponent = {
    a: ({ node, ...props }) => (
        <a {...props} target="_blank" rel="noopener noreferrer">
            {props.children}
        </a>
    ),
};
const crypto = require("crypto");
export const generateSHA256 = (email) => {
    const hash = crypto.createHash("sha256");
    hash.update(email);
    return hash.digest("hex");
};
