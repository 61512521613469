"use client";
import Link from "next/link";
import LoggedInMenu from "./logged-in";
import { useSelectAuthUser, useRedirect, useSelectLocale } from "@/hooks";
import { trackBuynowEvent } from "@/utils/gtm";
import { useSelector } from "react-redux";
import { DIGITS, LINKS, STRING_CONSTANTS } from "@/utils/constants";

export default function LoginMenu({
    quick_links,
    quick_links_auths,
    greetingText,
    csrfToken,
    loginUrl,
}) {
    const authUser = useSelectAuthUser();
    const locale = useSelectLocale();
    const redirect = useRedirect();
    const links = [];
    let username = authUser?.profile?.firstName || "";
    let dateOfBirth = authUser?.profile?.dateOfBirth || "";
    const gtmData = useSelector((state) => state?.authUser?.data);
    if ((quick_links?.length || 0) > 0) {
        quick_links.map(({ id, attributes }) => {
            if (attributes?.slug?.includes("buy-now")) {
                links.push(
                    <Link
                        key={id}
                        id="buy_now_header_desktop"
                        className="highlighted bold-iq"
                        href={attributes?.link?.link || "/#buy-now"}
                        title={attributes?.link?.text || ""}
                        onClick={(e) => {
                            if (!!attributes?.link?.isDisabled) {
                                e.preventDefault();
                                return;
                            }
                            trackBuynowEvent(
                                "e_headerinteraction",
                                "non_ecommerce",
                                attributes?.link.gtmName,
                                "millionaire",
                                gtmData?.lang || "en",
                                gtmData?.currentPage || "",
                                gtmData?.category || "",
                                gtmData?.subcategory || "",
                                gtmData?.previousPage || "",
                                gtmData?.loggedIn || "",
                                gtmData?.userid || ""
                            );
                        }}
                    >
                        {attributes?.link?.text || ""}
                    </Link>
                );
            } else {
                links.push(
                    <Link
                        key={id}
                        href={attributes?.link?.link || "/link-here"}
                        title={attributes?.link?.text || ""}
                        className="bold-iq"
                        onClick={(e) => {
                            if (!!attributes?.link?.isDisabled) {
                                e.preventDefault();

                                if (
                                    attributes?.slug?.includes("create-account")
                                )
                                    redirect(
                                        "/" + locale + LINKS?.NOT_AVAILABLE
                                    );

                                return;
                            }
                        }}
                    >
                        {attributes?.link?.text || ""}
                    </Link>
                );
            }
        });
    }

    return dateOfBirth?.length > DIGITS?.ZERO ? (
        <LoggedInMenu
            username={username || STRING_CONSTANTS?.USER}
            quick_links_auths={quick_links_auths}
            greetingText={greetingText}
            csrfToken={csrfToken || ""}
            loginUrl={loginUrl || ""}
        />
    ) : (
        <div className="header__login-menu">{links}</div>
    );
}
